<template>
  <Modal v-model="modal" width="1000" :title="$t('key1000296')">
    <Form
      ref="pageParams"
      :model="pageParams"
      :label-width="80"
      :rules="rules"
      @submit.native.prevent
    >
      <Row type="flex" :gutter="gutterItem">
        <Col
          :xxl="{ span: 8 }"
          :xl="{ span: 8 }"
          :lg="{ span: 8 }"
          :md="{ span: 8 }"
        >
          <FormItem :label="$t('key1000297')" prop="merchantId">
            <Select v-model="pageParams.merchantId" transfer>
              <Option
                v-for="item in workOptions"
                :key="item.value"
                :value="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </FormItem>
        </Col>
        <Col
          :xxl="{ span: 10 }"
          :xl="{ span: 10 }"
          :lg="{ span: 10 }"
          :md="{ span: 10 }"
        >
          <dropDownFilter
            ref="dropDownFilter"
            inputType="text"
            :selectStyle="{ width: '100px' }"
            :dropDownInputList="dropDownInputList"
            :defaultSelectValue="defaultSelectValue"
            @changeInput="changeInput"
            @updateInputValue="updateInputValue"
          >
          </dropDownFilter>
        </Col>
        <Col style="width: 200px" push="1">
          <Button @click="reset" icon="md-refresh" class="mr10">{{ $t('key1000094') }}</Button>
          <Button type="primary" @click="search" icon="md-search">{{ $t('key1000269') }}</Button>
        </Col>
      </Row>
    </Form>
    <div class="table_box">
      <Table
        ref="ingredientDom"
        :update-show-children="true"
        row-key="treeId"
        highlight-row
        border
        max-height="450"
        :load-data="handleLoadData"
        :loading="tableLoading"
        :columns="columns"
        :data="tableList"
      >
        <template #selectRow="{ row, index }">
          <Radio
            v-if="
              (selectType === 'spu' && row.parentLevel) ||
              (selectType === 'sku' && !row.parentLevel)
            "
            v-model="row.isMaster"
            @on-change="() => selectChange(row)"
          >
          </Radio>
        </template>
      </Table>
    </div>
    <template #footer>
      <Button @click="modal = false">{{ $t('key1000097') }}</Button>
      <Button type="primary" :disabled="!isSelected" @click="confirm"
        >{{ $t('key1000096') }}</Button
      >
    </template>
  </Modal>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

  import api from "@/api/index";
  import Mixin from "@/components/mixin/common_mixin";
  import dropDownFilter from "@/components/common/dropDownFilter";

  export default {
    name: "productCategory",
    mixins: [Mixin],
    props: {
      // spu  sku
      selectType: {
        type: String,
        default: () => "spu"
      },
      //  look
      operationType: {
        type: String,
        default: () => ""
      }
    },
    data() {
      let v = this;
      return {
        modal: false,
        isSelected: false,
        isEdit: false,
        isRequest: true,
        defaultSelectValue: 1,
        dropDownInputList: [
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000298'),
            value: 1,
            placeholder: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000299'),
            parameterKey: "spuList"
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000300'),
            value: 2,
            placeholder: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000301'),
            parameterKey: "skuList"
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000302'),
            value: 3,
            placeholder: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000303'),
            parameterKey: "cnName"
          }
        ],
        workOptions: [],
        rules: {
          merchantId: [
            { required: true, message: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000304'), trigger: "change" }
          ]
        },
        pageParams: {
          merchantId: "", //工厂
          pageSize: "50"
        },
        tableList: [],
        columns: [
          {
            title: "",
            key: "selectRow",
            align: "center",
            width: 50,
            renderHeader: (h) => {
              return h("span", "");
            },
            slot: "selectRow"
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000305'),
            minWidth: 320,
            key: "goodsInfo",
            align: "left",
            tree: true,
            className: "set_goodsInfo_box",
            render: (h, params) => {
              let imagePath = params.row.imagePath;
              let supplierSpu = params.row.spu;
              let name = params.row.cnName;
              let parentLevel = params.row.parentLevel;
              if (parentLevel) {
                let list = [
                  h("img", {
                    attrs: {
                      src: imagePath,
                      onerror:
                        "javascript:this.src='./static/images/placeholder.jpg'"
                    },
                    style: {
                      width: "64px",
                      height: "64px",
                      objectFit: "cover",
                      verticalAlign: "middle",
                      padding: "3px"
                    }
                  }),
                  h("div", { class: "flex flex-direction ml10" }, [
                    h("p", { class: "mt5 " }, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000085') + name),
                    h("p", { class: "mt5 " }, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000306') + supplierSpu)
                  ])
                ];
                return h("div", { class: "flex ptb10" }, list);
              } else {
                let list = [];
                let name = params.row.sku;
                let productGoodsSpecificationList =
                  params.row.productGoodsSpecifications || [];
                if (productGoodsSpecificationList.length > 0) {
                  list = productGoodsSpecificationList.map((item) => {
                    return item.value;
                  });
                }
                return h("div", { class: "flex wid100P text-align-left" }, [
                  h("p", { class: "" }, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000307') + name),
                  h("p", { class: "ml10 themeColor" }, list.join(" • "))
                ]);
              }
            }
          },
          {
            title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000308'),
            key: "category",
            align: "left",
            minWidth: 220,
            render: (h, params) => {
              return h("span", params.row.productCategoryNavigation);
            }
          }
        ],
        selectId: false,
        selectItem: null,
        isFirst: true
      };
    },
    components: { dropDownFilter },
    mounted() {
      this.initMainMaterialsData();
    },
    created() {},
    methods: {
      // 初始化数据
      initMainMaterialsData() {
        this.$nextTick(() => {
          const tableBody =
            this.$refs.ingredientDom.$el.querySelector(".ivu-table-body");
          if (tableBody) {
            // 使用节流函数来包装滚动事件处理函数
            const handleScroll = this.throttle(() => {
              const scrollTop = tableBody.scrollTop;
              const clientHeight = tableBody.clientHeight;
              const scrollHeight = tableBody.scrollHeight;
              // 设置一个误差值，比如 50 像素，作为滚动到底部的判断条件
              const tolerance = 20;
              if (scrollTop + clientHeight + tolerance >= scrollHeight) {
                // 判断滚动条是否接近底部
                this.getTableList();
              }
            }, 500); // 节流函数的执行间隔
            tableBody.addEventListener(
              "scroll",
              this.debounce(handleScroll, 500)
            ); // 使用防抖函数来包装节流函数
          }
        });
      },
      // 节流函数
      throttle(fn, delay) {
        let lastTime = 0;
        return function () {
          const now = new Date();
          if (now - lastTime >= delay) {
            lastTime = now;
            fn.apply(this, arguments);
          }
        };
      },
      // 防抖函数
      debounce(fn, delay) {
        let timer = null;
        return function () {
          clearTimeout(timer);
          timer = setTimeout(() => {
            fn.apply(this, arguments);
          }, delay);
        };
      },
      open(isEdit) {
        this.isFirst = true;
        this.pageParams.nextToken = null;
        this.isEdit = isEdit;
        this.isSelected = false;
        this.isRequest = true;
        this.tableList = [];
        this.getWorkOptions();
        this.initMainMaterialsData();
        this.modal = true;
        this.$nextTick(() => {
          const tableBody =
            this.$refs.ingredientDom.$el.querySelector(".ivu-table-body");
          if (tableBody) {
            setTimeout(() => {
              tableBody.scrollTop = 0;
            }, 1000);
          }
        });
      },
      // 获取下拉选项筛选条件的值
      updateInputValue(data) {
        if (data.key === "spuList" || data.key === "skuList") {
          this.pageParams[data.key] = data.value ? [data.value] : [];
        } else {
          this.pageParams[data.key] = data.value;
        }
      },
      // 改变默认下拉选项的值
      changeInput(value) {
        this.defaultSelectValue = value;
        this.$refs.dropDownFilter.inputValue = "";
        this.dropDownInputList.map((item) => {
          if (Object.keys(this.pageParams).includes(item.parameterKey)) {
            this.pageParams[item.parameterKey] =
              item.parameterKey === "spuList" || item.parameterKey === "skuList"
                ? []
                : "";
          }
        });
      },
      //  获取工厂options
      getWorkOptions() {
        const v = this;
        v.axios
          .get(
            v.systemTalg
              ? api.get_ymsFactory_api_options
              : api.get_ymsFactory_options
          )
          .then((response) => {
            const { code, datas } = response.data;
            if (code === 0) {
              v.workOptions = datas.map((item) => {
                return {
                  label: item.factoryName,
                  value: item.factoryMerchantId
                };
              });
              v.pageParams.merchantId = datas[0].factoryMerchantId;
              v.getTableList();
            }
          });
      },
      // 异步请求获取spu下所有的sku
      handleLoadData(item, callback) {
        let v = this;
        setTimeout(() => {
          let originalItem = JSON.parse(JSON.stringify(item));
          if (originalItem.children.length <= 0) {
            originalItem._loading = false;
            let productGoodsList = v.tableList.find(
              (fItem) => fItem.spu === item.spu
            ).productGoodsList;
            if (productGoodsList.length > 0) {
              productGoodsList = productGoodsList.map((pItem, i) => {
                let obj = {
                  ...pItem
                };
                obj.isMaster = false;
                obj.factoryMerchantId = v.pageParams.merchantId;
                obj.parentLevel = false;
                obj.treeId = "childLevel_" + pItem.sku;
                obj.selectType = "sku";
                obj.spu = item.spu;
                obj.cnName = item.cnName;
                obj.technologyTypeList = item.technologyTypeList;
                obj.imagePath = pItem.goodsImagePathList
                  ? pItem.goodsImagePathList[0]
                  : "";
                obj.productCategoryNavigation = item.productCategoryNavigation;
                return obj;
              });
              callback(productGoodsList);
              v.$forceUpdate();
            }
          } else {
            callback();
          }
        }, 300);
      },
      // 选中商品
      selectChange(item) {
        let v = this;
        v.isSelected = true;
        if (this.selectType === "spu") {
          v.tableList = v.tableList.map((sItem) => {
            let obj = {
              ...sItem,
              isMaster: false
            };
            if (sItem.treeId === item.treeId) {
              obj.isMaster = true;
            }
            return obj;
          });
        } else if (this.selectType === "sku") {
          v.tableList = v.tableList.map((pItem) => {
            let obj = {
              ...pItem
            };
            obj.children = pItem.children.map((cItem) => {
              let cObj = {
                ...cItem,
                isMaster: false
              };
              if (cItem.treeId === item.treeId) {
                cObj.isMaster = true;
              }
              return cObj;
            });
            return obj;
          });
        }
        v.selectItem = item;
        v.$forceUpdate();
      },
      search() {
        this.isRequest = true;
        this.pageParams.nextToken = null;
        this.tableList = [];
        this.getTableList();
      },
      reset() {
        this.changeInput(1);
        this.isRequest = true;
        this.pageParams.merchantId = this.workOptions[0].value;
        this.search();
      },
      // 获取表格数据
      getTableList() {
        let v = this;
        if (!v.isRequest) return;
        v.tableLoading = true;
        let params = Object.assign({}, v.pageParams);
        params.pageSize = 10;
        delete params.pageNum;
        v.axios
          .post(
            v.systemTalg
              ? api.post_ymsPodProduct_api_list
              : api.post_ymsPodProduct_list,
            params
          )
          .then((response) => {
            const { code, datas } = response.data;
            v.tableLoading = false;
            if (code === 0) {
              v.pageParams.nextToken = datas?.nextToken;
              if (!datas?.nextToken) {
                v.isRequest = false;
              }
              let newList = datas?.productApiResultBos || [];
              const arr = newList.map((item, index) => {
                let obj = {
                  ...item,
                  factoryMerchantId: v.pageParams.merchantId,
                  imagePath: item.productImagePathList
                    ? item.productImagePathList[0]
                    : "",
                  isMaster: false,
                  parentLevel: true,
                  treeId: "parentLevel_" + item.spu,
                  _loading: false,
                  selectType: "spu",
                  children: [],
                  _showChildren: false
                };
                if (this.isFirst) {
                  this.isFirst = false;
                  if (index === 0) {
                    obj._showChildren = true;
                    obj.children = item.productGoodsList.map((pItem, i) => {
                      let obj = {
                        ...pItem
                      };
                      obj.isMaster = false;
                      obj.factoryMerchantId = v.pageParams.merchantId;
                      obj.parentLevel = false;
                      obj.treeId = "childLevel_" + pItem.sku;
                      obj.selectType = "sku";
                      obj.spu = item.spu;
                      obj.cnName = item.cnName;
                      obj.technologyTypeList = item.technologyTypeList;
                      obj.imagePath = pItem.goodsImagePathList
                        ? pItem.goodsImagePathList[0]
                        : "";
                      obj.productCategoryNavigation =
                        item.productCategoryNavigation;
                      return obj;
                    });
                  }
                }
                return obj;
              });
              v.tableList = v.tableList.concat(arr);
            }
          });
      },
      // 修改类目确定按钮
      confirm() {
        this.modal = false;
        this.isRequest = true;
        this.$emit("selectedIngredient", this.selectItem, this.isEdit);
      }
    }
  };
</script>
<style lang="less">
  .table_box {
    .set_goodsInfo_box {
      .ivu-table-cell {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .productInfo_content {
          order: 1;
        }

        .ivu-table-cell-tree {
          order: 2;
        }
      }
    }
  }
</style>
