<template>
  <Tabs type="card" v-model="tabName" :animated="false" @on-click="changeTabs" class="wid100P">
    <TabPane v-for="(item,index) in tabsList" :key="index" :label="(h)=>labelRander(h, item)" :name="item.code">
      <!-- 在这里放置 TabPane 的内容 -->
      <slot :name="item.name"></slot>
    </TabPane>
  </Tabs>
</template>

<script>
import Mixin from "@/components/mixin/common_mixin";

export default {
  name: "languagesTabs",
  mixins: [Mixin],
  props: {
    tabsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let v = this;
    return {
      labelRander: (h, params) => {
        let title = params.title;
        let required = params.required;
        return h('div', {class: 'flex'}, [
          required ? h('span', {
            class: 'required_styles'
          }) : '',
          h('span', title),
        ]);
      },
      tabName: v.languagesVal || 'CN',
    }
  },
  methods: {
    // 切换tabs
    changeTabs(name) {
      this.$emit('changeLanguagesTabs', name);
    }
  }
}
</script>

<style lang="less" scoped>

/deep/ .ivu-tabs-tab .required_styles:before {
  content: '*';
  display: inline-block;
  line-height: 1;
  font-family: SimSun, serif;
  font-size: 12px;
  color: #ed4014;
  position: relative;
  top: -4px;
  margin-right: 2px;
}
</style>