import { render, staticRenderFns } from "./customColumnsDrawer.vue?vue&type=template&id=667667d5&scoped=true&"
import script from "./customColumnsDrawer.vue?vue&type=script&lang=js&"
export * from "./customColumnsDrawer.vue?vue&type=script&lang=js&"
import style0 from "./customColumnsDrawer.vue?vue&type=style&index=0&id=667667d5&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "667667d5",
  null
  
)

export default component.exports